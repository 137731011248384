import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  affectPermissionState,
  pushPermission,
} from "../../../slices/permission";
import pushPermissionID from "../../../utilities/pushPermissionID";

const Row = ({
  userId,
  row,
  className,
  options,
  userPermissions,
  permission,
  reset,
  setReset,
  permsionPath,
  permissiongroupestatus,
}) => {
  let LIST = [];
  let ID = [];

  const dispatch = useDispatch();
  const [value, setValue] = useState(LIST);
  useEffect(() => {
    pushPermissionID(userPermissions, ID, LIST, row);
  }, [userId]);

  const disable = row?.methods?.map((item) => item?.method);
  const handleChange = (e) => {
    const methode = row?.methods.find((item) => {
      return item?.method === e.target.value;
    });
    dispatch(pushPermission(methode._id));
    dispatch(affectPermissionState(true));
    if (value?.includes(e.target.value)) {
      setValue(value.filter((item) => item !== e.target.value));
    } else {
      setValue([...value, e.target.value]);
    }
  };

  useEffect(() => {
    ID.map((el) => dispatch(pushPermission(el)));
    setValue(LIST);
  }, [userId]);

  useEffect(() => {
    setValue([]);
  }, [permsionPath]);

  return (
    <TableRow>
      <>
        <TableCell className={className}>{row?.function}</TableCell>
        {options?.map((opt, index) => {
          return (
            <TableCell>
              <input
                type="checkbox"
                id={index}
                name="permission"
                value={opt.value}
                onChange={handleChange}
                checked={value.includes(opt.value)}
                disabled={disable.includes(opt.value) ? false : true}
              />
            </TableCell>
          );
        })}
      </>
    </TableRow>
  );
};

export default Row;
